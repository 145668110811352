export const APP_ROUTES = {
  dashboard: "/",
  login: "/login",
  resetPassword: "/reset-password",
  translations: "/translations",
  allGames: "/games/all-games",
  providers: "/games/providers",
  categories: "/games/categories",
  showGame: "/games/show/:id",
  terms: "/content/terms",
  banners: "/content/banners",
  faqs: "/content/faqs",
  pages: "/content/pages",
  news: "/content/news",
  imagesPack: "/content/images-pack",
  fileStorage: "/content/file-storage",
  promotions: "/promotions",
  calendars: "/promotions/calendars",
  tournaments: "/promotions/tournaments",
  showCalendar: "/promotions/calendars/:slug",
  accounts: "/accounts",
  accountCreate: "/account/new",
  accountEdit: "/account/:id",
  roles: "/roles",
  actionLogs: "/action-logs",
  brands: "/brands",
  customers: "/customers",
  bonusRequest: "/bonus-request",
  gateways: "/integrations/gateways",
  services: "/integrations/services",
  deposit: "/payments/deposit",
  withdrawal: "/payments/withdrawal",
  paymentsCategories: "/payments/categories",
  quiz: "/quiz",
  bonusDistribution: "/bonus-distribution",
};
