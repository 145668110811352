export const Role = {
  SuperAdmin: "Super Admin",
};

export const Group = {
  Gamebox: "Gamebox",
  Cashbox: "Cashbox",
  Admin: "Admin",
  Integrations: "Integrations",
  Brand: "Brand",
  Translate: "Translate",
  User: "User",
  ActivityLog: "ActivityLog",
  Storage: "Storage",
  Authorization: "Authorization",
  Core: "Core",
  Content: "Content",
  Terms: "Terms",
  Seo: "Seo",
  Quiz: "Quiz",
  Bonus: "Bonus",
  Domain: "Domains",
  Calendar: "Calendar",
  CalendarDay: "CalendarDay",
  MaliciousIPs: "MaliciousIPs",
};

export const Alias = {
  AdminPermission: "AdminPermission",
  AdminRole: "AdminRole",
  Admin: "Admin",
  Profile: "Profile",
  Authentication: "Authentication",
  PasswordReset: "PasswordReset",
  AdminBrand: "AdminBrand",
  Integration: "Integration",
  Brand: "Brand",
  BrandProvider: "BrandProvider",
  BrandGame: "BrandGame",
  BrandCategory: "BrandCategory",
  Bonus: "Bonus",
  Calendar: "Calendar",
  CalendarDay: "CalendarDay",
  MaliciousIp: "MaliciousIp",
  User: "User",
  UserClaim: "UserClaim",
  UserMobileStatus: "UserMobileStatus",
  Subject: "Subject",
  ActivityLog: "ActivityLog",
  SystemFile: "System-File",
  BrandFile: "Brand-File",
  Role: "Role",
  Permission: "Permission",
  RolePermission: "RolePermission",
  Bootstrap: "Bootstrap",
  Status: "Status",
  Page: "Page",
  Banner: "Banner",
  Faq: "Faq",
  News: "News",
  Image: "Image",
  Tournament: "Tournament",
  Terms: "Terms",
  Promotion: "Promotion",
  Seo: "Seo",
  Domain: "Domain",
  Quiz: "Quiz",
  Question: "Question",
  Answer: "Answer",
  Translate: "Translate",
  PackTranslate: "PackTranslate",
  SystemGateway: "System-Gateway",
  SystemMethodBrand: "System-MethodBrand",
  SystemMethod: "System-Method",
  BrandMethod: "BrandMethod",
  BrandGroup: "Brand-Group",
  GameboxBrandProvider: "Brand-Provider",
  GameboxBrandGameCategory: "Brand-GameCategory",
  GameboxBrandCategory: "Brand-Category",
  GameboxBrandCategoryGame: "Brand-CategoryGame",
  GameboxBrandGame: "Brand-Game",
  GameboxBrandProviderGame: "Brand-ProviderGame",
  GameboxSystemProvider: "System-Provider",
  GameboxSystemGameCategory: "System-GameCategory",
  GameboxSystemCategory: "System-Category",
  GameboxSystemGameBrand: "System-GameBrand",
  GameboxSystemCategoryGame: "System-CategoryGame",
  GameboxSystemProviderBrand: "System-ProviderBrand",
  GameboxSystemGame: "System-Game",
  GameboxSystemProviderGame: "System-ProviderGame",
  GameboxSystemCategoryBrand: "System-CategoryBrand",
};

export const Action = {
  index: "index",
  show: "show",
  store: "store",
  update: "update",
  destroy: "destroy",
  release: "release",
  position: "position",
  brands: "brands",
  roles: "roles",
  tags: "tags",
  users: "users",
  download: "get[download]",
  downloadTerms: "download",
  upload: "post[upload]",
  downloadStatCsv: "statCsv",
  statCalculate: "statCalculate",
  makeVerified: "makeVerified",
  makeUnverified: "makeUnverified",
};

export type permissionType = {
  group: string[];
  alias: string[];
  action: string[];
};
